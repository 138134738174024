import React, { useState, useRef } from "react";
import { graphql } from "gatsby";
import { isBrowser } from "../utility";

import Seo from "../components/seo";
import ArchiveTags from "../components/books/ArchiveTags";
import ArchiveSorting from "../components/books/ArchiveSorting";
import ArchiveBooks from "../components/books/ArchiveBooks";

import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { GridContainer, Container } from "../grid";

const sortingOptions = [
  {
    label: "Najprej najnovejše",
    slug: "date",
  },
  {
    label: "Po naslovu knjige",
    slug: "title",
  },
  {
    label: "Po avtorju knjige",
    slug: "author",
  },
];

const BooksArchive = ({ pageContext, data }) => {
  let queryBooks = data.allStrapiBook.books;
  // console.log(data)
  // console.log(pageContext)

  const queryString = isBrowser && new URLSearchParams(window.location.search);

  const booksContainer = useRef(null);
  const scrollToBooksView = () =>
    booksContainer.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const setQueryString = (key, value) => {
    if (queryString) {
      // console.log(queryString)
      queryString.set(key, value);
      const newRelativePathQuery =
        window.location.pathname + "?" + queryString.toString();
      window.history.pushState(null, "", newRelativePathQuery);
    }
  };

  /**
   * SET TAGS
   */
  let defaultTags = [];
  if (queryString && queryString.get("tag")) {
    defaultTags = queryString.get("tag").split(",");
  }

  const [selectedTags, setSelectedTag] = useState(defaultTags);

  const onDeleteTag = slug => {
    const tags = [...selectedTags].filter(tag => tag !== slug);
    setQueryString("tag", tags.join(","));
    setSelectedTag(tags);
    scrollToBooksView();
  };
  const onSelectTag = slug => {
    const tags = [...selectedTags, slug];
    setQueryString("tag", tags.join(","));
    setSelectedTag(tags);
    scrollToBooksView();
  };

  /**
   * SET SORTING
   * date | title | author
   */
  const querySortingTerm = queryString && queryString.get("sort");
  let defaultSorting = sortingOptions[0].slug;

  if (sortingOptions.find(o => o.slug === querySortingTerm)) {
    defaultSorting = querySortingTerm;
  }
  const [sorting, setSorting] = useState(defaultSorting);

  const handleSorting = slug => {
    setQueryString("sort", slug);
    setSorting(slug);
  };

  return (
    <>
      <Seo title={pageContext.name} />
      <Box
        sx={theme => ({
          backgroundColor: theme.palette.grey1,
        })}
      >
        <Container>
          <GridContainer>
            <Grid
              item
              mb={8}
              xl={4}
              sx={theme => ({
                position: { xl: "sticky" },
                top: theme.spacing(10),
              })}
            >
              <Typography variant="archiveTitle">{pageContext.name}</Typography>

              <GridContainer>
                <Grid item md={6} xl={12}>
                  {data.allStrapiBookTag.tags.length !== 0 && (
                    <Typography sx={{ mt: 7, mb: 3 }} variant="subtitle1">
                      Teme
                    </Typography>
                  )}
                  <ArchiveTags
                    tags={data.allStrapiBookTag.tags.sort((a, b) =>
                      a.data.slug.localeCompare(b.data.slug, "sl", {
                        numeric: true,
                      })
                    )}
                    selectedTags={selectedTags}
                    onDelete={slug => onDeleteTag(slug)}
                    onSelected={slug => onSelectTag(slug)}
                  />
                  {selectedTags.length >= 1 && (
                    <Button
                      onClick={() => {
                        setSelectedTag([]);
                        scrollToBooksView();
                      }}
                      sx={{ mt: 4 }}
                      size="small"
                    >
                      Prikaži vse knjige
                    </Button>
                  )}
                </Grid>
                <Grid item md={6} xl={12}>
                  <Typography sx={{ mt: 7, mb: 3 }} variant="subtitle1">
                    Razvsti
                  </Typography>
                  <ArchiveSorting
                    sortingOptions={sortingOptions}
                    selectedOption={sorting}
                    onClick={slug => handleSorting(slug)}
                  />
                </Grid>
              </GridContainer>
            </Grid>
            <Grid
              sx={theme => ({
                position: "relative",
              })}
              item
              xl={8}
            >
              <Box
                ref={booksContainer}
                sx={theme => ({
                  position: "absolute",
                  top: "-2rem",
                  left: 0,
                })}
              />
              <ArchiveBooks
                slug={pageContext.slug}
                selectedTags={selectedTags}
                sorting={sorting}
                books={queryBooks}
              />
            </Grid>
          </GridContainer>
        </Container>
      </Box>
    </>
  );
};

export default BooksArchive;

export const query = graphql`
  query ($slug: String, $langKey: String) {
    allStrapiBook(
      filter: { locale: { eq: $langKey }, category: { slug: { eq: $slug } } }
    ) {
      books: edges {
        data: node {
          strapi_id
          category {
            name
            slug
          }
          available
          id
          title
          slug
          subtitle
          author
          collection
          meta: childStrapiBookMetaTextnode {
            html: meta
          }
          desc: childStrapiBookDescTextnode {
            html: desc
          }
          prices {
            regular
            online
            discount
            uniform
          }
          attachments {
            label
            file {
              url
            }
          }
          cover {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: NONE, width: 1024)
              }
            }
          }
          tags {
            label
            slug
          }
          date
        }
      }
    }
    allStrapiBookTag(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
    ) {
      tags: edges {
        data: node {
          slug
          label
          id
        }
      }
    }
  }
`;
