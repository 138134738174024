import React from "react";
import { Box } from "@mui/material";
import styled from "@emotion/styled";
import { changeDecimal } from "../../utility/prices";

const Amount = styled.p`
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  line-height: 115%;
  position: relative;
  z-index: 5;
`;
const Label = styled.p`
  font-size: 0.75em;
  line-height: 115%;
  font-weight: 300;
  margin: 0;
`;

const Price = ({ amount, label, discount = false }) => (
  <Box
    sx={theme => ({
      position: "relative",
    })}
  >
    <Amount>{`${changeDecimal(amount)} EUR`}</Amount>
    <Label>{label}</Label>
    {discount && (
      <Box
        sx={theme => ({
          fontWeight: "bold",
          bgcolor: theme.palette.grey4,
          borderRadius: "50%",
          display: "block",
          width: "2.5em",
          height: "2.5em",
          textAlign: "center",
          lineHeight: "2.5em",
          fontSize: "0.8em",
          position: "absolute",
          right: 0,
          top: 0,
          transform: "translate(120%, 0%) rotate(0deg)",
        })}
      >
        -%
      </Box>
    )}
  </Box>
);

export default function Prices({ data, sx }) {
  let { regular, online, discount, uniform, preorder } = data;

  const dispPrices = () => {
    if (preorder) {
      return <Price amount={preorder} label="prednaročniška cena" />;
    } else if (uniform) {
      return <Price amount={uniform} label="enotna cena" />;
    } else if (!discount) {
      return (
        <>
          <Price amount={regular} label="redna cena" />
          {online && <Price amount={online} label="spletna cena" />}
        </>
      );
    } else {
      return <Price amount={discount} discount label="znižana cena" />;
    }
  };

  return <Box sx={sx}>{dispPrices()}</Box>;
}
