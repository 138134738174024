import React from 'react';

import Box from "@mui/system/Box"
import Chip from "@mui/material/Chip"
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export default function ArchiveTags({tags, selectedTags = [], onDelete, onSelected}) {
  //console.log(tags)
  return (
    <Box sx = { (theme) => ({
      display: 'flex',
      gap: theme.spacing(2),
      flexWrap: 'wrap'
    })}>
      {tags.map(({data}) => {
        return (
          selectedTags.includes(data.slug) ? (
            <Chip
              key={data.slug}
              label={data.label}
              variant="outlined"
              deleteIcon={<ClearOutlinedIcon />}
              onDelete={() => onDelete(data.slug)}
            />
          ) : (
            <Chip
              key={data.slug}
              label={data.label}
              variant="outlined"
              onClick={() => onSelected(data.slug)}
            />
          )
          
        )
      })}
    </Box>
  )
};

