import React from 'react';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

export const ArchiveBtn = ({children, icon, state, onClick, delay, badge = false}) => (
  <Box 
    sx = { (theme) => ({
      fontSize: {
        xs: '1.5rem'
      },
      lineHeight: 1,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(3),
      transform: state === 'entered' ? `scale(1)` : `scale(0)`,
      transition: `transform ${theme.transitions.duration.standard}ms ${theme.transitions.easing.easeOutCubic} ${delay}ms`,
      cursor: 'pointer',
      '& .label': {
        fontSize: '0.5em',
        fontWeight: 400,
        textTransform: 'uppercase',
        lineHeight: '125%',
        whiteSpace: 'nowrap',
      },
      '& .icon-badge': {
        marginBottom: '0.25em'
      }
    })}
    onClick={onClick}
  >
    { badge ?
      <Badge
        badgeContent={badge ? <CheckRoundedIcon/> : 0} 
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        sx = { (theme) => ({
            '& .MuiBadge-badge': {
              bottom: '0.5em',
              right: '0.25em',
              color: theme.palette.white,
              backgroundColor: theme.palette.primary.main,
              width: '1.4em',
              height: '1.4em',
              lineHeight: '1.4em',
              borderRadius: '50%',
              '& svg': {
                fontSize: '1em'
              }
            },
          })
        }
      >
        <div className='icon icon-badge'>{icon}</div>
      </Badge>
      :
      <div className='icon'>{icon}</div>
    
    
    } 
    
    <div className='label'>{children}</div>
  </Box>
)
