import React, { useContext, useState, forwardRef } from 'react';
import { navigate } from 'gatsby';

import { Cover } from './Cover';
import Prices from './Prices';
import { cartContext } from '../../contexts';
import { addToCart } from '../../contexts/actions';
import {ArchiveBtn} from './ArchiveBtn';
import {CartIcon, MoreIcon} from './../icons'

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { Transition } from 'react-transition-group';


export const SingleBook = forwardRef((props, ref) => {
  
  const data = props.book 
  // console.log(data)

  const {cart, dispatchCart} = useContext(cartContext)

  const [hover, setHover] = useState(false)
  const [clickable, setClickable] = useState(false)
  const [inCart, setInCart] = useState(
    cart.findIndex( item => item.id === data.strapi_id ) !== -1
  )

  const clickHandler = () => {
    navigate(`/${data.category.slug}/${data.slug}`)
  }
  const addCartHandler = ({id, title, author, prices, cover, slug}) => {
    setInCart(true)
    dispatchCart(addToCart({
      id,
      title,
      author,
      prices,
      cover,
      slug,
      type: 'book'
    }, 1))
  }
  return (
  <div ref={ref} {...props} style={{alignSelf: 'start'}}>
    <Transition in={hover} timeout={200}>
      {
        (state) => (
          <Box 
            sx = { (theme) => ({
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: {
                xs: 4,
                md: 6,
                lg: 6,
                // xl: 8
              },
            })} 
            onMouseEnter={() => {
              setHover(true)
              setInterval(()=>setClickable(true), 500)
            }}
            onMouseLeave={() => {
              setHover(false)
              setClickable(false)
            }}
            className={`${state}`}
          >
            <Box sx = { (theme) => ({
              width: '100%',
              flexShrink: 0,
              position: 'relative',
              zIndex: 5,
            })}>
              
              <Box sx = { (theme) => ({
                width: '85%',
                display: 'flex',
                flexDirection: {
                  xs: 'column',
                  //md: 'row'
                },
                alignContent: 'center',
                justifyContent: 'center',
                gap: {
                  xs: theme.spacing(3),
                  lg: theme.spacing(3)
                },
                position: 'absolute',
                right: {
                  xs: theme.spacing(2),
                  lg: theme.spacing(2)
                },
                top: theme.spacing(2),
                left: theme.spacing(2),
                '&>div': {
                  flex: '0 1 auto',
                  justifyContent: 'center',
                  width: {
                    xs: '100%',
                    //lg: '50%'
                  }
                }
              })}>
                {data.available && 
                  <ArchiveBtn 
                    onClick={()=>{
                      if (inCart) {
                        navigate('/kosarica')
                      } else {
                        addCartHandler({
                          id: data.strapi_id,
                          title: data.title,
                          author: data.author,
                          prices: data.prices,
                          cover: data.cover.localFile,
                          slug: `/${data.category.slug}/${data.slug}`
                        })
                      }
                    }} 
                    delay={0} state={state} 
                    icon={<CartIcon/>}
                    badge={inCart}
                  >
                      { inCart ? 'Zaključi nakup' : 'Dodaj v košarico' }
                  </ArchiveBtn>
                }
                <ArchiveBtn
                  onClick={clickHandler} 
                  delay={0} 
                  state={state} 
                  icon={<MoreIcon/>}>{data.category.slug === 'kamisibaji' ? 'Več o kamišibaju' : 'Več o knjigi' }</ArchiveBtn>
              </Box>
              
              <Cover
                onClick={() => {
                  clickable && clickHandler()
                }}
                img={data.cover?.localFile} 
                alt={data.title} 
                gatsbyimageprops={{
                  loading: 'lazy',
                }}
                sx = {((theme) => ({
                  transition: `transform 0.25s ${theme.transitions.easing.easeOutCubic} 0.35s, scale 0.25s ${theme.transitions.easing.easeOutCubic} 0s`,
                  transform: state === 'entered' ? 'translateY(5rem)' : 'translateY(0)',
                  scale: state === 'entered' ? '0.95' : '1',
                  zIndex: 5,
                  cursor: 'pointer',
                  width: '90%'
                }))}
              />
            </Box>

            <Box sx = { (theme) => ({
              backgroundColor: theme.palette.grey1,
              position: 'relative',
              zIndex: 10,
              width: 'calc( 100% + 1rem)',
              minHeight: '6rem',
              pt: {xs: 2 },
              ml: {xs: -2},
              pl: {xs: 3}
            })}>
                <Typography variant='bookArchiveAuthor'>{data.author}</Typography>
                <Typography variant='bookArchiveTitle'>{data.title}</Typography>
                {/* {data.subtitle && <Typography variant='bookArchiveSubtitle'>{data.subtitle}</Typography>} */}
                <Prices sx = { (theme) => ({
                  mt: 4,
                  display: 'flex',
                  flexDirection: {
                    xs: 'column',
                    md: 'row'
                  },
                  alignItems: {
                    xs: 'flex-start'
                  },
                  gap: {
                    xs: theme.spacing(2),
                    md2: theme.spacing(4)
                  }
                })}
                 data={data.prices}/>
            </Box>
          </Box>
        )
      }
      
    </Transition>
  </div>
  )
});