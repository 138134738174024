import React from 'react';

import Box from '@mui/material/Box';
import { Grow } from '@mui/material';

import { TransitionGroup} from 'react-transition-group'

import { getAuthorSurname } from '../../utility';
import { SingleBook } from './ArchiveSingleBook';

export default function ArchiveBooks({slug, books, selectedTags=[], sorting = 'date'}) {

  // Filter
  if (selectedTags.length > 0) {
    // console.log(`filtering... ${selectedTags}`)
    books = books.filter(({data}) => {
      return data.tags.some( ({slug}) => selectedTags.includes(slug))
    })
  }

  // Sort
  switch (sorting) {
    case 'author':
      console.log(`sorting by author ...`)
      books = books.sort((a, b) => getAuthorSurname(a.data.author).localeCompare(getAuthorSurname(b.data.author), 'sl'))
      break;
    case 'title':
      console.log(`sorting by title ...`)
      books = books.sort((a, b) => {
        const titleA = a.data.title[0].toLowerCase()
        const titleB = b.data.title[0].toLowerCase()
        return titleA.localeCompare(titleB)
      })
      break;
    default:
      console.log(`sorting by date ...`)
      books = books.sort((a, b) => {
        const aDate = new Date(a.data.date)
        const bDate = new Date(b.data.date)
        return (aDate.getTime() > bDate.getTime()) ? -1 : 1
      })
      break;
  }
  
  return (
    <Box
      sx = { (theme) => ({
        '&>div': {
          width: '100%',
          justifyContent: 'center',
          display: 'grid',
          columnGap: {
            xs: theme.spacing(4),
            xl: theme.spacing(8)
          },
          rowGap:{
            xs: theme.spacing(8),
            md: theme.spacing(10)
          },
          gridTemplateColumns: {
            xs: (slug === 'kamisibaji') ? 'minmax(0, 1fr)' : 'repeat(2, minmax(0, 1fr))',
            md: (slug === 'kamisibaji') ? 'repeat(2, minmax(0, 1fr))' : 'repeat(3, minmax(0, 1fr))',
          }
        }
      })}
    >
      <TransitionGroup>
        {books.map((book, ind) => (
          <Grow 
            key={`${book.data.slug}`}
            timeout={{
              enter: 500,
              appear: 500,
              exit: 200,
            }}
          >
            <SingleBook book={book.data} slug={slug}/>
          </Grow>
        ))}
      </TransitionGroup>
    </Box>
  )
};
