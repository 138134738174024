import React from 'react';

import Box from "@mui/system/Box"
import Chip from "@mui/material/Chip"

export default function ArchiveSorting({selectedOption, sortingOptions, onClick}) {
  

  return (
    <Box sx = { (theme) => ({
      display: 'flex',
      gap: theme.spacing(2),
      flexWrap: 'wrap'
    })}>
      {sortingOptions.map(({label, slug}) => {
        return (
          (slug === selectedOption) ? (
            <Chip
              key={slug}
              label={label}
              variant="filled"
            />
          ) : (
            <Chip
              key={slug}
              label={label}
              variant="outlined"
              onClick={() => onClick(slug)}
            />
          )
          
        )
      })}
    </Box>
  )
};

