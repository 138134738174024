export const selectPrice = (prices, customerType) => {
  if (prices.uniform) {
    return prices.uniform
  } else if (prices.discount) {
    return prices.discount
  } else {
    if (prices.online && customerType === 'person'){
      return prices.online
    } else {
      return prices.regular
    }
  }
};

export const getDiscount = (books, customerType) => {
  
  // get number of all books
  const booksNum = books.reduce(
    (accumulator, currentElement) => accumulator + currentElement.qty,
     0
  )
  
  // Set discount factor
  let factor = 0
  if (booksNum >= 50) {
    factor = 0.3
  } else if (booksNum >= 30){
    factor = 0.25
  } else if (booksNum >= 10){
    factor = 0.2
  } else if (booksNum >= 5){
    factor = 0.15
  } else if (booksNum >= 2){
    factor = 0.1
  }

  // Calculate discount  
  let discount = books.reduce(
    (accumulator, currentElement) => {
      /**
       * if:
       * the book has uniform price or
       * is already in discount or
       * is a customer a person and a book has online price
       * don't apply discount
       */
      if (
        currentElement.prices.uniform || 
        currentElement.prices.discount ||
        ( customerType === 'person' && currentElement.prices.online )
      ) {
        return accumulator
      } else {
        return accumulator + (currentElement.total * factor)
      }
    }, 0
  )
  return discount
}


export const changeDecimal = value => String(value?.toFixed(2)).replace('.', ',');


export const getShippingCost = (books, total, customerType, customerData, shippingData) => {
  
  // get number of books
  const booksNum = books.reduce(
    (accumulator, currentElement) => accumulator + currentElement.qty,
     0
  )

  let country = (shippingData.shippingCountry) ? shippingData.shippingCountry.code : (customerType === 'person' ? customerData.country?.code : customerData.companyCountry?.code)

  if (country === 'SI') {
    return (total >= 18) ? 0 : 4
  } else {
    return 10 + ((booksNum - 1) * 4 )
  }
}